<template>
  <div>
    <top :name="text" />
    <!-- 空状态 -->
    <van-empty
      v-if="cart_data.length == 0"
      image="https://mall.yaolvxing.com/image/cart.png"
      description="购物车空空的"
    />
    <!-- 空状态 End-->
    <div class="cart_div">
      <van-checkbox-group v-model="result" ref="checkboxGroup">
        <van-checkbox
          @click="click_par()"
          v-for="(item, index) in cart_data"
          :key="index"
          :name="index"
          style="padding-bottom: 10px"
        >
          <van-card
            :num="item.sku_num"
            :price="item.unit_price"
            :desc="item.trade_sku"
            :title="item.trade_name"
            :thumb="item.trade_image"
          >
            <template #price>
              <p style="font-size: 0.3rem; color: red">
                ￥{{ item.unit_price }} +0积分
              </p>
            </template>
          </van-card>
        </van-checkbox>
      </van-checkbox-group>
    </div>

    <van-submit-bar
      :price="money * 100"
      button-text="提交订单"
      @submit="onshow"
      suffix-label=" +0积分"
    >
      <van-checkbox v-model="checked" type="primary" @click="checkAll"
        >全选/反选</van-checkbox
      >
    </van-submit-bar>
    <!-- 结算防遮挡 -->
    <div style="width: 100%; height: 13.333333vw"></div>
    <bottom />

    <!-- 点击结算弹出框 -->
    <van-popup v-model="show" position="bottom" closeable round>
      <div class="popup_view">
        <p class="chick_p">请选择收货地址并确认购买商品</p>
        <div class="dizhi">
          <div class="dizhi_div" @click="select">
            <div class="middleInfo">
              <div class="shouhuoren">
                <div class="picker">{{ pick_name }}</div>
                <div class="phone">{{ pick_phone }}</div>
              </div>
              <div class="shouhuodizhi">
                <span class="detailAddress">{{ pick_dizhi }}</span>
              </div>
            </div>
            <div class="rightIcon">
              <img
                style="width: 0.22rem; height: 0.22rem"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAA3NCSVQICAjb4U/gAAAAKlBMVEX///+3t7e3t7e3t7e3t7e3t7e3t7e3t7e3t7e3t7e3t7e3t7e3t7e3t7cn0wXWAAAADnRSTlMAESIzRHeImaq7zN3u//RDEIsAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAWdEVYdENyZWF0aW9uIFRpbWUAMDMvMjcvMTdPXTLGAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAAGdJREFUGJVjYGBgEDVgQIC1V5A4d+8gScXePYzgsN29o4Dg1d7dhOCw370tgOD13l2I4HDevYWQYpx7dyJCSuruDQSHce3dAgTP9u4F7DLIelBMQ7EH2QUobkN2NYp/UHyKEgbw0AEAumwr1Mwo4uIAAAAASUVORK5CYII="
                alt=""
              />
            </div>
          </div>
        </div>
        <!-- 选购商品显示 -->
        <div v-for="(item, index) in data_json" :key="index">
          <van-card
            style="
              border-bottom: 1px solid #f4f4f4;
              background: none;
              margin-top: 0.3rem;
            "
            :num="item.sku_num"
            :price="item.total_price"
            :origin-price="
              item.sku_num > 1 ? item.unit_price : item.total_price
            "
            :desc="item.trade_sku"
            :title="item.trade_name"
            :thumb="item.trade_image"
          >
            <template #price>
              <p style="font-size: 0.3rem; color: red">
                ￥{{ item.total_price }} +0积分
              </p>
            </template>
          </van-card>
        </div>
        <div style="width: 100%; height: 13.333333vw"></div>
        <van-submit-bar
          style="bottom: 0"
          :price="money * 100"
          button-text="提交订单"
          @submit="onSubmit"
          suffix-label=" +0积分"
        />
      </div>
    </van-popup>
  </div>
</template>

<script lang="ts">
import top from "../components/filltop.vue";
import wxx from "weixin-jsapi";
import bottom from "../components/bottom.vue";
import { Dialog } from "vant";
export default {
  name: "cart",
  components: {
    bottom,
    top,
  },
  data() {
    return {
      // 控制结算弹出框
      show: false,
      text: "购物车",
      result: [],
      dep: 0,
      checked: false,
      cart_data: {},
      // 提交支付金额
      money: 0,
      data_json: {},
      pick_data: {},
      pick_name: "请选择配送地址",
      pick_phone: "",
      pick_dizhi: "",
    };
  },
  methods: {
    // 点击复选框时 换算价格
    click_par() {
      // 临时价格
      var a = 0,
        // 选择的商品
        data_json = [];
      // 循环选择的复选框 价格相加
      this.result.forEach((item, index) => {
        a = parseInt(this.cart_data[item].total_price) + a;
        data_json.push(this.cart_data[item]);
      });
      // 价格赋值
      this.money = a;
      // 选择的商品赋值
      this.data_json = data_json;
    },
    checkAll() {
      this.dep + 1;
      this.dep % 0 == 0
        ? this.$refs.checkboxGroup.toggleAll(true)
        : this.$refs.checkboxGroup.toggleAll();
      // 调用复选框事件
      this.click_par();
    },
    onshow() {
      this.result.length == 0
        ? this.$toast.fail("请至少选择一件商品")
        : (this.show = true);
    },
    // 选择收货人地址
    select() {
      this.$router.push({
        name: "addresslist",
      });
      sessionStorage.setItem("id", "900");
    },
    // 订单提交
    onSubmit() {
      var that = this;
      this.$https(
        "cart/cartplay",
        "id=" +
          sessionStorage.getItem("user_id") +
          "&phone=" +
          sessionStorage.getItem("user_phone") +
          "&address=" +
          this.pick_data.id +
          "&jsondata=" +
          JSON.stringify(this.data_json),
        "post"
      ).then((res) => {
        if (res.data.code == "000000") {
          // 已选择的商品清空
          this.data_json = [];
          // 结算金额清空
          this.money = 0;
          // 勾选清空
          this.result = [];
          // 弹窗隐蔽
          this.show = false;
          var is_m_p = res.data.data.substring(0, 1);
          var type = "";
          // orge 单个订单 tion多个订单
          is_m_p == "M" ? (type = "orge") : (type = "tion");
          // cart/cartwechat
          this.$https(
            "cart/cartwechat",
            "id=" +
              sessionStorage.getItem("user_id") +
              "&wxid=" +
              sessionStorage.getItem("wx_openid") +
              "&phone=" +
              sessionStorage.getItem("user_phone") +
              "&source=" +
              "1" +
              "&type=" +
              type +
              "&url=" +
              encodeURIComponent(window.location.href.split("#")[0]) +
              "&orderid=" +
              res.data.data,
            "post"
          ).then((resg) => {
            wxx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: "wx0343cf05decc1643", // 必填，公众号的唯一标识
              timestamp: resg.data.data.timeStamp, // 必填，生成签名的时间戳
              nonceStr: resg.data.data.nonceStr, // 必填，生成签名的随机串
              signature: resg.data.data.paySign, // 必填，签名
              jsApiList: ["chooseWXPay"], // 必填，需要使用的JS接口列表
            }),
              wxx.ready(() => {
                // alert("成功")
                wxx.chooseWXPay({
                  timestamp: resg.data.data.timeStamp,
                  nonceStr: resg.data.data.nonceStr,
                  package: resg.data.data.package,
                  signType: "RSA",
                  paySign: resg.data.data.paySign,
                  // 用户支付成功和取消支付都跳转到订单详情页面
                  success: function () {
                    // 用户支付成功
                    that.$router.replace({
                      name: "personalcenter",
                    });
                  },
                  fail: function () {
                    that.onbayWXnavto(resg);
                    Dialog.alert({
                      title: "查询支付状态",
                    }).then(() => {
                      that.$router.push({
                        path: "/many/more",
                        query: {
                          id: "all",
                        },
                      });
                    });
                  },
                  cancel: function (err) {
                    // 用户取消支付
                    that.$router.replace({
                      name: "personalcenter",
                    });
                  },
                });
              }),
              wxx.error((res) => {
                alert("进行wx.error失败");
              });
          });
        } else {
          this.$toast.fail(res.data.msg);
        }
      });
    },
    list() {
      this.$https(
        "mall/getcart",
        "phone=" +
          sessionStorage.getItem("user_phone") +
          "&id=" +
          sessionStorage.getItem("user_id"),
        "post"
      ).then((res) => {
        if (res.data.code == "-10") {
          res.data.data = [];
        }
        this.cart_data = res.data.data;
        this.click_par();
      });
    },
    // 页面拉起微信支付失败跳转小程序拉起微信支付
    onbayWXnavto(succ) {
      // 用户支付失败 再跳转小程序进行支付 在小程序页面进行订单删除操作
      //
      // npm方式注入sdk失败  操作dom创建标签
      var wxjs = document.createElement("script");
      wxjs.type = "text/javascript";
      wxjs.src = "https://res.wx.qq.com/open/js/jweixin-1.6.0.js";
      document.body.appendChild(wxjs);
      // 异步调用sdk方法
      wxjs.onload = () => {
        // 伪定义 wx
        // eslint-disable-next-line no-undef
        wx.miniProgram.navigateTo({
          url:
            "/webH5/wxpay/wxpay?success_res=" +
            encodeURIComponent(JSON.stringify(succ)),
        });
      };
    },
  },
  mounted() {
    // if (sessionStorage.getItem("dizhi") != null) {
    // }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name == "addresslist") {
        console.log("返回", vm.data_json);
        vm.show = true;
        // 选择了收货人才进行渲染
        JSON.parse(sessionStorage.getItem("dizhi")) &&
          ((vm.pick_data = JSON.parse(sessionStorage.getItem("dizhi"))),
          (vm.pick_name = vm.pick_data.name),
          (vm.pick_phone = vm.pick_data.tel),
          (vm.pick_dizhi =
            vm.pick_data.province +
            vm.pick_data.city +
            vm.pick_data.area +
            vm.pick_data.address));
      } else {
        vm.$https(
          "mall/getcart",
          "phone=" +
            sessionStorage.getItem("user_phone") +
            "&id=" +
            sessionStorage.getItem("user_id"),
          "post",
          "false"
        ).then((res) => {
          if (res.data.code == "-10") {
            res.data.data = [];
          }
          vm.cart_data = res.data.data;
          vm.click_par();
        });
      }
    });
  },
};
</script>

<style scoped>
.dizhi {
  width: 100%;
  margin-top: 0.4rem;
  height: auto;
  border: 1px solid #f3f3f3;
  background-image: linear-gradient(#6fb5fe, #ffffff);
  padding: 0.2rem;
}
.dizhi_div {
  padding-top: 0.3rem;
  border-radius: 0.1rem;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 0.3rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  color: #00234e;
}
.middleInfo {
  width: 6rem;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.shouhuoren {
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.3rem;
}
.picker {
  max-width: 4rem;
  line-height: 1.5;
  margin-right: 0.7rem;
  font-weight: 900;
}
.phone {
  max-width: 2.5rem;
  line-height: 1.5;
}
.shouhuodizhi {
  margin-top: 0.2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.detailAddress {
  width: 5rem;
  font-size: 0.3rem;
  line-height: 1.5;
}
.rightIcon {
  line-height: 1.2rem;
  display: flex;
  align-items: center;
  text-align: center;
}
.chick_p {
  width: 100%;
  display: block;
  text-align: center;
  font-size: 0.36rem;
  margin-top: 0.26rem;
}
.popup_view {
  height: 80vh;
}
#app {
  background: #f5f5f5;
}
.van-checkbox >>> .van-checkbox__label {
  width: 100%;
}
.van-image >>> .van-image__img {
  border: 1px solid #eaeaea;
}
.cart_div {
  width: 100%;
  height: auto;
  background: white;
  padding: 0rem 0rem 0rem 0.1rem;
}
.van-checkbox__label >>> .van-card {
  background-color: none;
}
.van-submit-bar {
  bottom: 1rem;
}
.van-card__price >>> div {
  color: red;
}
</style>